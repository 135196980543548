import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import ApiClient from "lib/ApiClient";
import LoginContext from "context/LoginContext";

export default function Login() {
  const loginContext = useContext(LoginContext);
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    const formData = new FormData(event.target);
    ApiClient
      .post('/admin/login', formData)
      .then((response) => {
        switch(response.data.result) {
          case 'SUCCESS':
            loginContext.logIn(response.data.userAccountId);
            navigate('/secure/identity_verification_request/');
            break;
          case 'FAILURE':
            break;
          default:
            break;
        }
      });

    event.preventDefault();
  }

  return (
    <div id="Login">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField margin="normal" required fullWidth id="email" label="メールアドレス" name="email" autoComplete="email" autoFocus/>
          <TextField margin="normal" required fullWidth name="password" label="パスワード" type="password" id="password" autoComplete="current-password"/>
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
            ログイン
          </Button>
        </Box>
      </Box>
    </div>
  );
}
