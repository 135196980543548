import { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import CssBaseline from "@mui/material/CssBaseline";
import ApiClient from 'lib/ApiClient';
import LoginContext from "context/LoginContext";
import Login from 'Login';
import SecureApp from 'secure/App';
import './App.css';

function App() {
  useEffect(() => {
    ApiClient
        .get('/admin/session')
        .then((response) => {
          // CSRFトークンをCookieにセットするためだけのリクエストなのでここでは特に何もしない
        });
  });

  const [loggedIn, setLoggedIn] = useState(false);
  const [currentUserAccountId, setCurrentUserAccountId] = useState("");

  const logIn = (userAccountId) => {
    setLoggedIn(true);
    setCurrentUserAccountId(userAccountId);
  }

  const logOut = () => {
    setLoggedIn(false);
    setCurrentUserAccountId(null);
  }

  return (
    <div className="App">
      <CssBaseline />
      <LoginContext.Provider value={{
        loggedIn: loggedIn,
        currentUserAccountId: currentUserAccountId,
        logIn: logIn,
        logOut: logOut
      }} >
        <BrowserRouter>
          <Routes>
            <Route exact path='/' element={<Login />} />
            <Route path="/secure/*" element={<SecureApp />} />
          </Routes>
        </BrowserRouter>
      </LoginContext.Provider>
    </div>
  );
}

export default App;
