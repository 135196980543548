import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ApiClient from "lib/ApiClient";
import Loading from "components/Loading";
import moment from 'lib/moment_ja';
import './Index.scss';

/**
 * 提供内容に関する問題報告管理
 *
 * @param {function} setTitle
 * @returns {JSX.Element}
 * @constructor
 */
export default function DeliveryComplaintIndex({setTitle}) {
  const navigate = useNavigate();

  const [complaints, setComplaints] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setTitle("提供内容に関する問題報告");

    setLoading(true);
    ApiClient
      .get('/secure/admin/delivery_complaints')
      .then((response) => {
        setComplaints(response.data.deliveryComplaints);
        setLoading(false);
      });
  }, []);

  const rowClicked = (id) => {
    navigate(`/secure/delivery_complaint/${id}`);
  }

  return (
    <div id="DeliveryComplaintIndex">
      <Loading loading={loading} />
      <Container fixed>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">ID</TableCell>
                <TableCell align="center">ステータス</TableCell>
                <TableCell align="center">サービス名</TableCell>
                <TableCell align="center">購入者</TableCell>
                <TableCell align="center">提供報告日時</TableCell>
                <TableCell align="center">送信日時</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                complaints.map((c) => (
                  <TableRow
                    key={c.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer'}}
                    className={c.state}
                    onClick={() => rowClicked(c.id)}
                  >
                    <TableCell component="th" scope="row">{c.id}</TableCell>
                    <TableCell>{c.state}</TableCell>
                    <TableCell>{c.serviceMenu.title}</TableCell>
                    <TableCell>{c.buyer.displayName}（ID：{c.buyer.id}）</TableCell>
                    <TableCell>{moment(c.deliveryReport.reportedAt).format("YYYY年M月D日 H:mm")}</TableCell>
                    <TableCell>{moment(c.submittedAt).format("YYYY年M月D日 H:mm")}</TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </div>
  );
}