import { TailSpin } from "react-loader-spinner";
import './Loading.scss';

export default function Loading({loading}) {
  if (loading) {
    return (
      <div className="loadingWrapper">
        <div className="loader">
          <TailSpin color="#5CB531" height="100" width="100"/>
        </div>
      </div>
    );
  }
  else {
    return (<></>);
  }
}