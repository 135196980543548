import { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import ApiClient from "lib/ApiClient";

export default function IdentityVerificationRequestShow({setTitle}) {
  const navigate = useNavigate();

  const { identityVerificationRequestId } = useParams();

  const [request, setRequest] = useState({});
  const [userIdentification, setUserIdentification] = useState({});

  const [openDialog, setOpenDialog] = useState(false);
  const [rejectionReason, setRejectionReason] = useState("");

  useEffect(() => {
    setTitle("本人確認");

    ApiClient
      .get(`/secure/admin/identity_verification_requests/${identityVerificationRequestId}`)
      .then((response) => {
        switch(response.data.result) {
          case 'SUCCESS':
            setRequest(response.data.identityVerificationRequest);
            setUserIdentification(response.data.identityVerificationRequest.userIdentification);
            break;
          case 'FAILURE':
            break;
          default:
            break;
        }
      });
  }, [identityVerificationRequestId]);

  const acceptButtonClicked = () => {
    ApiClient
      .put(`/secure/admin/identity_verification_requests/${identityVerificationRequestId}/accept`)
      .then((response) => {
        switch(response.data.result) {
          case 'SUCCESS':
            navigate('/secure/identity_verification_request/');
            break;
          case 'FAILURE':
            break;
          default:
            break;
        }
      });
  }

  const rejectButtonClicked = () => {
    setOpenDialog(true);
  }

  const cancelRejectionButtonClicked = () => {
    setOpenDialog(false);
  }

  const submitRejectionButtonClicked = () => {
    ApiClient
      .put(`/secure/admin/identity_verification_requests/${identityVerificationRequestId}/reject`, {
        reason: rejectionReason
      })
      .then((response) => {
        switch(response.data.result) {
          case 'SUCCESS':
            setOpenDialog(false);
            navigate('/secure/identity_verification_request/');
            break;
          case 'FAILURE':
            break;
          default:
            break;
        }
      });
  }

  return (
    <div id="IdentityVerificationRequestShow">
      <Container fixed>
        <Stack direction="column" spacing={2}>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell align="center">申請時内容</TableCell>
                  <TableCell align="center">現在内容</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>個人／法人</TableCell>
                  <TableCell>{ request.corporationFlag ? '法人' : '個人' }</TableCell>
                  <TableCell>{ userIdentification.corporationFlag ? '法人' : '個人' }</TableCell>
                </TableRow>
                {
                  request.corporationFlag ? (
                    <>
                      <TableRow>
                        <TableCell>法人名</TableCell>
                        <TableCell>{ request.corporationName }</TableCell>
                        <TableCell>{ userIdentification.corporationName }</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>登記所在地</TableCell>
                        <TableCell>{ request.corporationPrefecture }{ request.corporationAddress }</TableCell>
                        <TableCell>{ userIdentification.corporationPrefecture }{ userIdentification.corporationAddress }</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>代表者氏名</TableCell>
                        <TableCell>{ `${request.corporationRepLastName} ${request.corporationRepFirstName}` }</TableCell>
                        <TableCell>{ `${userIdentification.corporationRepLastName} ${userIdentification.corporationRepFirstName}` }</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>担当者氏名</TableCell>
                        <TableCell>{ `${request.lastName} ${request.firstName}` }</TableCell>
                        <TableCell>{ `${userIdentification.lastName} ${userIdentification.firstName}` }</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>担当者住所</TableCell>
                        <TableCell>{ `${request.prefecture} ${request.address}` }</TableCell>
                        <TableCell>{ `${userIdentification.prefecture} ${userIdentification.address}` }</TableCell>
                      </TableRow>
                    </>
                  ) : (
                    <>
                      <TableRow>
                        <TableCell>氏名</TableCell>
                        <TableCell>{ `${request.lastName} ${request.firstName}` }</TableCell>
                        <TableCell>{ `${userIdentification.lastName} ${userIdentification.firstName}` }</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>住所</TableCell>
                        <TableCell>{ request.prefecture }{ request.address }</TableCell>
                        <TableCell>{ userIdentification.prefecture }{ userIdentification.address }</TableCell>
                      </TableRow>
                    </>
                  )
                }
                <TableRow>
                  <TableCell>本人確認ファイル</TableCell>
                  <TableCell colSpan={2}><a href={request.s3Url} target="_blank" rel="noreferrer">{request.s3Url}</a></TableCell>
                </TableRow>
                { request.rejection &&
                  <TableRow>
                    <TableCell>否認理由</TableCell>
                    <TableCell colSpan={2} sx={{ whiteSpace: "pre-wrap" }}>{request.rejection.reason}</TableCell>
                  </TableRow>
                }
              </TableBody>
            </Table>
          </TableContainer>
          {
            request.state === 'requested' ? (
              <Stack direction="row" spacing={1}>
                <Button variant="contained" color="success" onClick={() => acceptButtonClicked()}>承認する</Button>
                <Button variant="outlined" color="error" onClick={() => rejectButtonClicked()}>否認する</Button>
              </Stack>
            ) : null
          }
        </Stack>
      </Container>
      <Dialog open={openDialog}>
        <DialogTitle>本人確認を否認する</DialogTitle>
        <DialogContent>
          <DialogContentText>否認理由を入力してください（入力した内容は対象ユーザーに通知されます）。</DialogContentText>
          <TextField autoFocus multiline={true} margin="dense" id="reason" label="否認理由" type="text" fullWidth variant="standard" onChange={(event) => {
            setRejectionReason(event.target.value);
          }} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => cancelRejectionButtonClicked()}>キャンセル</Button>
          <Button onClick={() => submitRejectionButtonClicked()} disabled={rejectionReason === ''}>送信</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}