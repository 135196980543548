import { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import ApiClient from "lib/ApiClient";
import moment from "lib/moment_ja";
import Loading from "components/Loading";
import config from 'Config';

export default function SellerExaminationRequestShow({setTitle}) {
  const navigate = useNavigate();

  const { sellerExaminationRequestId } = useParams();

  const [request, setRequest] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setTitle("高額出品申請");

    setLoading(true);

    ApiClient
      .get(`/secure/admin/seller_examination_requests/${sellerExaminationRequestId}`)
      .then((response) => {
        switch(response.data.result) {
          case 'SUCCESS':
            setRequest(response.data.sellerExaminationRequest);
            break;
          case 'FAILURE':
            break;
          default:
            break;
        }

        setLoading(false);
      });
  }, [sellerExaminationRequestId]);

  const acceptButtonClicked = () => {
    setLoading(true);

    ApiClient
      .put(`/secure/admin/seller_examination_requests/${sellerExaminationRequestId}/approve`)
      .then((response) => {
        switch(response.data.result) {
          case 'SUCCESS':
            navigate('/secure/seller_examination_request/');
            break;
          case 'FAILURE':
            break;
          default:
            break;
        }
        setLoading(false);
      });
  }

  const rejectButtonClicked = () => {
    setLoading(true);

    ApiClient
      .put(`/secure/admin/seller_examination_requests/${sellerExaminationRequestId}/reject`)
      .then((response) => {
        switch(response.data.result) {
          case 'SUCCESS':
            navigate('/secure/seller_examination_request/');
            break;
          case 'FAILURE':
            break;
          default:
            break;
        }
        setLoading(false);
      });
  }

  return (
    <div id="SellerExaminationRequestShow">
      <Loading loading={loading} />
      <Container fixed>
        <Stack direction="column" spacing={2}>
          <TableContainer>
            <Table aria-label="simple table">
              <TableBody>
                <TableRow>
                  <TableCell align="center">ID</TableCell>
                  <TableCell align="center">{sellerExaminationRequestId}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">ステータス</TableCell>
                  <TableCell align="center">{request.state}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">ユーザー</TableCell>
                  <TableCell align="center">
                    {
                      request.userAccount && (
                        <>
                          <a href={`${config.frontendBaseUrl}/users/${request.userAccount.id}`}
                             target="_blank"
                             rel="noreferrer">{request.userAccount.displayName}（ID：{request.userAccount.id}）</a><br />
                          <a href={`${config.frontendBaseUrl}/secure/talk_room/new/${request.userAccount.id}`}
                              target="_blank"
                              rel="noreferrer">事務局アカウントとのトークルーム</a>
                        </>
                      )
                    }
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">SNS・ホームページ等のURL</TableCell>
                  <TableCell align="center"><a href={request.url} target="_blank" rel="noreferrer">{request.url}</a></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">対象商品のPARK以外での販売状況</TableCell>
                  <TableCell align="center">{
                    { 1: '販売を行っていない', 2: '販売を行っているが、販売実績はない', 3: '販売を行っており、販売実績がある' }[request.salesState]
                  }</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">PARK以外でご利用の決済ツール・決済手段</TableCell>
                  <TableCell align="center">{request.otherPaymentMethods || '-'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">申請日時</TableCell>
                  <TableCell align="center">{moment(request.createdAt).format("YYYY年M月D日 H:mm")}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          {
            request.state === 'requested' && (
              <Stack direction="row" spacing={1}>
                <Button variant="contained" color="success" onClick={() => acceptButtonClicked()}>承認する</Button>
                <Button variant="outlined" color="error" onClick={() => rejectButtonClicked()}>否認する</Button>
              </Stack>
            )
          }
        </Stack>
      </Container>
    </div>
  );
}