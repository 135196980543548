import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import ApiClient from "lib/ApiClient";
import Stack from "@mui/material/Stack";
import { Select, MenuItem } from "@mui/material";
import Button from "@mui/material/Button";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { DatePicker } from '@mui/x-date-pickers';
import TextField from '@mui/material/TextField';
import locale from 'date-fns/locale/ja';
import Loading from "components/Loading";
import { ToastContainer, toast } from 'react-toastify';
import {format} from "date-fns";
import Typography from "@mui/material/Typography";
import config from "../../Config";

export default function WithdrawalRequestShow({setTitle}) {
  const { withdrawalRequestId } = useParams();

  const [withdrawalRequest, setWithdrawalRequest] = useState(null);

  const [state, setState] = useState("");
  const [scheduledWithdrawalDate, setScheduledWithdrawalDate] = useState(null);
  const [bankAccountHolderName, setBankAccountHolderName] = useState("");
  const [bankAccountBankType, setBankAccountBankType] = useState("");
  const [bankAccountBankName, setBankAccountBankName] = useState("");
  const [bankAccountBankCode, setBankAccountBankCode] = useState("");
  const [bankAccountBranchName, setBankAccountBranchName] = useState("");
  const [bankAccountBranchCode, setBankAccountBranchCode] = useState("");
  const [bankAccountAccountType, setBankAccountAccountType] = useState("");
  const [bankAccountAccountCodeNumber, setBankAccountAccountCodeNumber] = useState("");
  const [bankAccountAccountNumber, setBankAccountAccountNumber] = useState("");

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setTitle("出金申請");

    loadWithdrawalRequest();
  }, [withdrawalRequestId]);

  const loadWithdrawalRequest = () => {
    setLoading(true);
    ApiClient
      .get(`/secure/admin/withdrawal_requests/${withdrawalRequestId}`)
      .then((response) => {
        switch(response.data.result) {
          case "SUCCESS":
            setWithdrawalRequest(response.data.withdrawalRequest);

            setState(response.data.withdrawalRequest.state);
            setScheduledWithdrawalDate(new Date(response.data.withdrawalRequest.scheduledWithdrawalDate));
            setBankAccountHolderName(response.data.withdrawalRequest.bankAccount.holderName);
            setBankAccountBankType(response.data.withdrawalRequest.bankAccount.bankType);
            setBankAccountBankName(response.data.withdrawalRequest.bankAccount.bankName);
            setBankAccountBankCode(response.data.withdrawalRequest.bankAccount.bankCode);
            setBankAccountBranchName(response.data.withdrawalRequest.bankAccount.branchName);
            setBankAccountBranchCode(response.data.withdrawalRequest.bankAccount.branchCode);
            setBankAccountAccountType(response.data.withdrawalRequest.bankAccount.accountType);
            setBankAccountAccountCodeNumber(response.data.withdrawalRequest.bankAccount.accountCodeNumber);
            setBankAccountAccountNumber(response.data.withdrawalRequest.bankAccount.accountNumber);

            break;
          case "FAILURE":
            break;
        }
        setLoading(false);
      });
  }

  const updateButtonClicked = () => {
    setLoading(true);
    ApiClient
      .put(`/secure/admin/withdrawal_requests/${withdrawalRequestId}`, {
        scheduled_withdrawal_date: format(scheduledWithdrawalDate, 'yyyy-MM-dd'),
        bank_account_holder_name: bankAccountHolderName,
        bank_account_bank_type: bankAccountBankType,
        bank_account_bank_name: bankAccountBankName,
        bank_account_bank_code: bankAccountBankCode,
        bank_account_branch_name: bankAccountBranchName,
        bank_account_branch_code: bankAccountBranchCode,
        bank_account_account_type: bankAccountAccountType,
        bank_account_account_code_number: bankAccountAccountCodeNumber,
        bank_account_account_number: bankAccountAccountNumber
      })
      .then((response) => {
        switch(response.data.result) {
          case "SUCCESS":
            toast.success("変更を保存しました");
            break;
          case "FAILURE":
            toast.error("エラーが発生しました");
            break;
        }
        setLoading(false);
      });
  }

  const cancelButtonClicked = () => {
    setLoading(true);
    ApiClient
      .put(`/secure/admin/withdrawal_requests/${withdrawalRequestId}/cancel`)
      .then((response) => {
        switch(response.data.result) {
          case "SUCCESS":
            toast.success("出金申請を取り消しました");
            loadWithdrawalRequest();
            break;
          case "FAILURE":
            toast.error("エラーが発生しました");
            break;
        }
        setLoading(false);
      });
  }

  return withdrawalRequest && (
    <div id="WithdrawalRequestShow">
      <Loading loading={loading} />
      <ToastContainer />
      <Stack>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableBody>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>{withdrawalRequest.id}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>ユーザー</TableCell>
                <TableCell>
                  {withdrawalRequest.userAccount.displayName}（ID：{withdrawalRequest.userAccount.id}）<br/>
                  <a href={`${config.frontendBaseUrl}/secure/talk_room/new/${withdrawalRequest.userAccount.id}`}
                     target="_blank"
                     rel="noreferrer">事務局アカウントとのトークルーム</a><br />
                  <a href={`/secure/impersonation?user_account_id=${withdrawalRequest.userAccount.id}`}>なり代わりログイン</a>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>出金申請金額</TableCell>
                <TableCell>{withdrawalRequest.grossAmount.toLocaleString()}円</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>ステータス</TableCell>
                <TableCell>
                  <Typography>{withdrawalRequest.state}</Typography>
                  {
                    withdrawalRequest.state === "requested" && (
                      <Button variant={"contained"} color="error" onClick={() => cancelButtonClicked()}>申請を取り消し</Button>
                    )
                  }
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>申請日</TableCell>
                <TableCell>{withdrawalRequest.requestedOn}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>出金予定日</TableCell>
                <TableCell>
                  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
                    <DatePicker label="出金予定日"
                                mask="____/__/__"
                                value={scheduledWithdrawalDate}
                                onChange={(date) => setScheduledWithdrawalDate(date) }
                                renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>振込先：口座名義</TableCell>
                <TableCell>
                  <TextField
                    value={bankAccountHolderName}
                    sx={{ verticalAlign: "middle" }}
                    onChange={(e) => setBankAccountHolderName(e.target.value)}
                  />
                  （現在登録情報：{withdrawalRequest.userAccount.bankAccount.holderName}）
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>振込先：銀行種別</TableCell>
                <TableCell>
                  <Select variant={"outlined"} value={bankAccountBankType} onChange={(e) => setBankAccountBankType(e.target.value)}>
                    <MenuItem value="bank">ゆうちょ以外の銀行・信用金庫</MenuItem>
                    <MenuItem value="yucho">ゆうちょ銀行</MenuItem>
                  </Select>
                  （現在登録情報：{withdrawalRequest.userAccount.bankAccount.bankType}）
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>振込先：銀行名</TableCell>
                <TableCell>
                  <TextField
                    value={bankAccountBankName}
                    sx={{ verticalAlign: "middle" }}
                    onChange={(e) => setBankAccountBankName(e.target.value)}
                  />
                  （現在登録情報：{withdrawalRequest.userAccount.bankAccount.bankName}）
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>振込先：銀行コード</TableCell>
                <TableCell>
                  <TextField
                    value={bankAccountBankCode}
                    sx={{ verticalAlign: "middle" }}
                    onChange={(e) => setBankAccountBankCode(e.target.value)}
                  />
                  （現在登録情報：{withdrawalRequest.userAccount.bankAccount.bankCode}）
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>振込先：支店名</TableCell>
                <TableCell>
                  <TextField
                    value={bankAccountBranchName}
                    sx={{ verticalAlign: "middle" }}
                    onChange={(e) => setBankAccountBranchName(e.target.value)}
                  />
                  （現在登録情報：{withdrawalRequest.userAccount.bankAccount.branchName}）
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>振込先：支店コード</TableCell>
                <TableCell>
                  <TextField
                    value={bankAccountBranchCode}
                    sx={{ verticalAlign: "middle" }}
                    onChange={(e) => setBankAccountBranchCode(e.target.value)}
                  />
                  （現在登録情報：{withdrawalRequest.userAccount.bankAccount.branchCode}）
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>振込先：口座種別</TableCell>
                <TableCell>
                  <Select
                    value={bankAccountAccountType}
                    sx={{ verticalAlign: "middle" }}
                    onChange={(e) => setBankAccountAccountType(e.target.value)}
                  >
                    <MenuItem value="saving">普通</MenuItem>
                    <MenuItem value="checking">当座</MenuItem>
                  </Select>
                  （現在登録情報：{withdrawalRequest.userAccount.bankAccount.accountType}）
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>振込先：口座記号</TableCell>
                <TableCell>
                  <TextField
                    value={bankAccountAccountCodeNumber}
                    sx={{ verticalAlign: "middle" }}
                    onChange={(e) => setBankAccountAccountCodeNumber(e.target.value)}
                  />
                  （現在登録情報：{withdrawalRequest.userAccount.bankAccount.accountCodeNumber}）
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>振込先：口座番号</TableCell>
                <TableCell>
                  <TextField
                    value={bankAccountAccountNumber}
                    sx={{ verticalAlign: "middle" }}
                    onChange={(e) => setBankAccountAccountNumber(e.target.value)}
                  />
                  （現在登録情報：{withdrawalRequest.userAccount.bankAccount.accountNumber}）
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
      {
        withdrawalRequest.state === "requested" && (
          <Stack sx={{ marginTop: "1rem" }}>
            <Button variant={"contained"} onClick={() => updateButtonClicked()}>変更を反映する</Button>
          </Stack>
        )
      }
    </div>
  );
}