import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import ApiClient from "lib/ApiClient";
import moment from "lib/moment_ja";
import Loading from "components/Loading";
import './Index.scss';
import Button from "@mui/material/Button";

/**
 * 銀行振込管理
 *
 * @param {function} setTitle
 * @returns {JSX.Element}
 * @constructor
 */
export default function BankTransferIndex({setTitle}) {
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [loading, setLoading] = useState(false);

  const [totalNumberOfBankTransfers, setTotalNumberOfBankTransfers] = useState(0);
  const [bankTransfers, setBankTransfers] = useState([]);

  useEffect(() => {
    setTitle("銀行振込管理");

    loadPage();
  }, [page, itemsPerPage]);

  const loadPage = () => {
    setLoading(true);

    ApiClient
      .get(`/secure/admin/bank_transfers?page=${page}&items_per_page=${itemsPerPage}`)
      .then((response) => {
        setTotalNumberOfBankTransfers(response.data.numberOfBankTransfers);
        setBankTransfers(response.data.bankTransfers);
        window.scrollTo(0, 0);
        setLoading(false);
      });
  }

  return (
    <div id="BankTransferIndex">
      <Loading loading={loading} />
      <Container fixed>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">ID</TableCell>
                <TableCell align="center">振込番号</TableCell>
                <TableCell align="center">振込人名義</TableCell>
                <TableCell align="center">ステータス</TableCell>
                <TableCell align="center">支払い金額</TableCell>
                <TableCell align="center">申請日時</TableCell>
                <TableCell align="center">入金確認</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                bankTransfers.map((bt) => (
                  <TableRow
                    key={bt.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 }}}
                    className={bt.state}
                  >
                    <TableCell component="th" scope="row" align="right">{bt.id}</TableCell>
                    <TableCell align="center">{bt.transferNumber}</TableCell>
                    <TableCell align="center">{bt.senderName}</TableCell>
                    <TableCell align="center">
                      { bt.state === 'waiting_for_deposit' && (
                        <span>入金確認待ち</span>
                      )}
                      { bt.state === 'deposited' && (
                        <>
                          <span>入金確認済み</span>
                        </>
                      )}
                    </TableCell>
                    <TableCell align="right">{bt.paymentAmount.toLocaleString()}円</TableCell>
                    <TableCell align="center">{moment(bt.createdAt).format("YYYY年M月D日 H:mm")}</TableCell>
                    <TableCell align="center">
                      {
                        bt.state === 'waiting_for_deposit' ? (
                          <Button
                            variant="contained"
                            color="success"
                            onClick={() => {
                              setLoading(true);
                              ApiClient
                                .put(`/secure/admin/bank_transfers/${bt.id}/complete`)
                                .then(() => {
                                  loadPage();
                                });
                            }}
                          >入金確認</Button>
                        ) : (
                          moment(bt.depositedOn).format("YYYY年M月D日")
                        )
                      }
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={totalNumberOfBankTransfers}
          page={page}
          onPageChange={(event, newPage) => { setPage(newPage) }}
          rowsPerPage={itemsPerPage}
          onRowsPerPageChange={(event) => {
            setItemsPerPage(parseInt(event.target.value));
            setPage(0);
          }}
        />
      </Container>
    </div>
  );
}