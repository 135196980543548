import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import ApiClient from "lib/ApiClient";
import moment from "lib/moment_ja";
import './Index.scss';

export default function IdentityVerificationIndex({setTitle}) {
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(25);

  const [totalNumberOfRequests, setTotalNumberOfRequests] = useState(0);
  const [requests, setRequests] = useState([]);

  useEffect(() => {
    setTitle("本人確認");

    loadPage();
  }, [page, itemsPerPage]);

  const loadPage = () => {
    ApiClient
      .get(`/secure/admin/identity_verification_requests?page=${page}&items_per_page=${itemsPerPage}`)
      .then((response) => {
        setTotalNumberOfRequests(response.data.numberOfIdentityVerificationRequests);
        setRequests(response.data.identityVerificationRequests);
        window.scrollTo(0, 0);
      });
  }

  const rowClicked = (request_id) => {
    navigate(`/secure/identity_verification_request/${request_id}`);
  }

  return (
    <div id="IdentityVerificationIndex">
      <Container fixed>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">ID</TableCell>
                <TableCell align="center">ステータス</TableCell>
                <TableCell align="center">ユーザー</TableCell>
                <TableCell align="center">申請日時</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                requests.map((r) => (
                  <TableRow
                    key={r.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer'}}
                    onClick={() => rowClicked(r.id)}
                    className={r.state}
                  >
                    <TableCell component="th" scope="row" align="right">{r.id}</TableCell>
                    <TableCell align="center">
                      { r.state === 'requested' && (
                        <span>承認待ち</span>
                      )}
                      { r.state === 'accepted' && (
                        <>
                          <span>承認</span>
                          <span>（{r.judger.displayName || '-'}）</span>
                        </>
                      )}
                      { r.state === 'rejected' && (
                        <>
                          <span>否認</span>
                          <span>（{r.judger.displayName || '-'}）</span>
                        </>
                      )}
                    </TableCell>
                    <TableCell align="center">{r.userDisplayName}（ID：{r.userAccountId}）</TableCell>
                    <TableCell align="center">{moment(r.requestedAt).format("YYYY年M月D日 H:mm")}</TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={totalNumberOfRequests}
          page={page}
          onPageChange={(event, newPage) => { setPage(newPage) }}
          rowsPerPage={itemsPerPage}
          onRowsPerPageChange={(event) => {
            setItemsPerPage(parseInt(event.target.value));
            setPage(0);
          }}
        />
      </Container>
    </div>
  );
}