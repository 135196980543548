import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ApiClient from "lib/ApiClient";
import moment from 'lib/moment_ja';

export default function ReservationComplaintIndex({setTitle}) {
  const navigate = useNavigate();

  const [complaints, setComplaints] = useState([]);

  useEffect(() => {
    setTitle("開催に関するお問い合わせ");

    ApiClient
      .get('/secure/admin/reservation_complaints')
      .then((response) => {
        setComplaints(response.data.reservationComplaints);
      });
  }, []);

  const rowClicked = (id) => {
    navigate(`/secure/reservation_complaint/${id}`);
  }

  return (
    <div id="ReservationComplaintIndex">
      <Container fixed>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>ステータス</TableCell>
                <TableCell>開催タイトル</TableCell>
                <TableCell>開催日時</TableCell>
                <TableCell>購入者</TableCell>
                <TableCell>送信日時</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                complaints.map((c) => (
                  <TableRow
                    key={c.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer'}}
                    onClick={() => rowClicked(c.id)}
                  >
                    <TableCell component="th" scope="row">{c.id}</TableCell>
                    <TableCell>{c.state}</TableCell>
                    <TableCell>{c.serviceScheduleTitle}</TableCell>
                    <TableCell>{moment(c.serviceScheduleStartAt).format("YYYY年M月D日 H:mm")} 〜 {moment(c.serviceScheduleEndAt).format("H:mm")}</TableCell>
                    <TableCell>{c.userDisplayName}（ID：{c.userAccountId}）</TableCell>
                    <TableCell>{moment(c.submittedAt).format("YYYY年M月D日 H:mm")}</TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </div>
  );
}