import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Stack from "@mui/material/Stack";
import ApiClient from "lib/ApiClient";
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow, Typography, Checkbox
} from "@mui/material";
import Loading from "components/Loading";
import Button from "@mui/material/Button";

/**
 * @param {function} setTitle
 * @returns {JSX.Element}
 * @constructor
 */
export default function ServiceMenuShow({setTitle}) {
  const { serviceMenuId } = useParams();

  const [serviceMenu, setServiceMenu] = useState(null);
  const [items, setItems] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setTitle("商品管理");
    reloadServiceMenu();
  }, []);

  const reloadServiceMenu = () => {
    setLoading(true);
    ApiClient
      .get(`/secure/admin/service_menus/${serviceMenuId}`)
      .then((response) => {
        switch(response.data.result) {
          case "SUCCESS":
            setServiceMenu(response.data.serviceMenu);
            setItems(response.data.items);
            break;
          case "FAILURE":
            toast.error("エラーが発生しました");
            break;
        }
        setLoading(false);
      })
  }

  /**
   * @param {number} itemId
   * @param {boolean} checked
   */
  const bankTransferEnabledFlagChanged = (itemId, checked) => {
    const newItems = items.map((item) => {
      if (item.id === itemId) {
        return {
          ...item,
          bankTransferEnabledFlag: checked
        };
      } else {
        return item;
      }
    });

    setItems(newItems);
  }

  /**
   * @param {number} itemId
   * @param {boolean} checked
   */
  const creditCardEnabledFlagChanged = (itemId, checked) => {
    const newItems = items.map((item) => {
      if (item.id === itemId) {
        return {
          ...item,
          creditCardEnabledFlag: checked
        };
      } else {
        return item;
      }
    });

    setItems(newItems);
  }

  const applyButtonClicked = () => {
    setLoading(true);

    ApiClient
      .put(`/secure/admin/items/bulk_update`, {
        items: items.map((item) => {
          return {
            id: item.id,
            bank_transfer_enabled_flag: item.bankTransferEnabledFlag,
            credit_card_enabled_flag: item.creditCardEnabledFlag
          }
        })
      })
      .then((response) => {
        switch(response.data.result) {
          case "SUCCESS":
            toast.success("設定を適用しました");
            break;
          case "FAILURE":
            toast.error(`エラーが発生しました：${response.data.errorMessage}`);
            break;
        }
        setLoading(false);
      })
  }

  return serviceMenu && (
    <div id="ServiceMenuShow">
      <Loading loading={loading} />
      <ToastContainer />
      <Stack>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">ID</TableCell>
              <TableCell align="center">商品名</TableCell>
              <TableCell align="center">提供方法</TableCell>
              <TableCell align="center">出品者</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key={serviceMenu.id}>
              <TableCell align="center">{serviceMenu.id}</TableCell>
              <TableCell align="center">{serviceMenu.title}</TableCell>
              <TableCell align="center">{serviceMenu.providingMethod}</TableCell>
              <TableCell align="center">{serviceMenu.userAccount.displayName}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Stack>
      <Stack sx={{ marginTop: "2rem" }}>
        <Typography variant="subtitle1" component="h3" align="left">アイテム一覧</Typography>
        <Stack direction="row" spacing={2} sx={{ marginTop: "1rem" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">アイテムID</TableCell>
                <TableCell align="center">アイテム名</TableCell>
                <TableCell align="center">金額</TableCell>
                <TableCell align="center">オプション</TableCell>
                <TableCell align="center">
                  銀行振込可
                  {
                    items.some((item) => item.bankTransferAvailableFlag) && (
                      <Checkbox
                        checked={
                          items.filter((item) => item.bankTransferAvailableFlag).every((item) => item.bankTransferEnabledFlag)
                        }
                        onChange={(event) => {
                          setItems(items.map((item) => {
                            if (item.bankTransferAvailableFlag) {
                              return {
                                ...item,
                                bankTransferEnabledFlag: event.target.checked
                              };
                            } else {
                              return item;
                            }
                          }))
                        }}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    )
                  }
                </TableCell>
                <TableCell align="center">
                  クレジットカード決済可
                  <Checkbox
                    checked={
                      items.every((item) => item.creditCardEnabledFlag)
                    }
                    onChange={(event) => {
                      setItems(items.map((item) => {
                        return {
                          ...item,
                          creditCardEnabledFlag: event.target.checked
                        }
                      }))
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                items.map((item, index) => (
                  <TableRow key={item.id}>
                    <TableCell align="center">{item.id}</TableCell>
                    <TableCell align="center">{item.name}</TableCell>
                    <TableCell align="center">{item.price.toLocaleString()}円</TableCell>
                    <TableCell align="center">{item.type === 'optional' ? '✔︎' : '-︎'}</TableCell>
                    <TableCell align="center">
                      {
                        item.bankTransferAvailableFlag && (
                          <Checkbox
                            checked={item.bankTransferEnabledFlag}
                            onChange={(event) => bankTransferEnabledFlagChanged(item.id, event.target.checked)}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                        )
                      }
                    </TableCell>
                    <TableCell align="center">
                      {
                        <Checkbox
                          checked={item.creditCardEnabledFlag}
                          onChange={(event) => creditCardEnabledFlagChanged(item.id, event.target.checked)}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </Stack>
        <Stack sx={{ marginTop: "1rem" }}>
          <Button variant={"contained"} onClick={() => applyButtonClicked()}>チェックしたアイテムに設定を適用</Button>
        </Stack>
      </Stack>
    </div>
  );
}