import { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import ApiClient from "lib/ApiClient";
import moment from "lib/moment_ja";

export default function ReservationComplaintShow({setTitle}) {
  const { reservationComplaintId } = useParams();

  const [complaint, setComplaint] = useState({});

  const [openSalesVerificationDialog, setOpenSalesVerificationDialog] = useState(false);
  const [openReservationCancellationWithChargeDialog, setOpenReservationCancellationWithChargeDialog] = useState(false);
  const [openReservationCancellationWithoutChargeDialog, setOpenReservationCancellationWithoutChargeDialog] = useState(false);

  const cancelSalesVerificationButtonClicked = () => {
    setOpenSalesVerificationDialog(false);
  }

  const submitSalesVerificationButtonClicked = () => {
    ApiClient
      .put(`/secure/admin/reservation_complaints/${reservationComplaintId}/reject_with_participation`)
      .then((response) => {
        switch(response.data.result) {
          case 'SUCCESS':
            setComplaint(response.data.reservationComplaint);
            setOpenSalesVerificationDialog(false);
            break;
          case 'FAILURE':
            break;
          default:
            break;
        }
      });
  }

  const cancelReservationCancellationWithChargeButtonClicked = () => {
    setOpenReservationCancellationWithChargeDialog(false);
  }

  const submitReservationCancellationWithChargeButtonClicked = () => {
    ApiClient
      .put(`/secure/admin/reservation_complaints/${reservationComplaintId}/reject_without_participation`)
      .then((response) => {
        switch(response.data.result) {
          case 'SUCCESS':
            setComplaint(response.data.reservationComplaint);
            setOpenReservationCancellationWithChargeDialog(false);
            break;
          case 'FAILURE':
            break;
          default:
            break;
        }
      });
  }

  const cancelReservationCancellationWithoutChargeButtonClicked = () => {
    setOpenReservationCancellationWithoutChargeDialog(false);
  }

  const submitReservationCancellationWithoutChargeButtonClicked = () => {
    ApiClient
      .put(`/secure/admin/reservation_complaints/${reservationComplaintId}/accept`)
      .then((response) => {
        switch(response.data.result) {
          case 'SUCCESS':
            setComplaint(response.data.reservationComplaint);
            setOpenReservationCancellationWithoutChargeDialog(false);
            break;
          case 'FAILURE':
            break;
          default:
            break;
        }
      });
  }

  useEffect(() => {
    setTitle("開催に関するお問い合わせ");

    ApiClient
      .get(`/secure/admin/reservation_complaints/${reservationComplaintId}`)
      .then((response) => {
        switch(response.data.result) {
          case 'SUCCESS':
            setComplaint(response.data.reservationComplaint);
            break;
          case 'FAILURE':
            break;
          default:
            break;
        }
      });
  }, [reservationComplaintId]);

  return (
    <div id="ReservationComplaintShow">
      <Container fixed>
        <Stack direction="column" spacing={2}>
          <TableContainer>
            <Table aria-label="simple table">
              <TableBody>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>{complaint.id}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>ステータス</TableCell>
                  <TableCell>{complaint.state}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>開催タイトル</TableCell>
                  <TableCell>{complaint.serviceScheduleTitle}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>開催日時</TableCell>
                  <TableCell>{moment(complaint.serviceScheduleStartAt).format("YYYY年M月D日 H:mm")} 〜 {moment(complaint.serviceScheduleEndAt).format("H:mm")}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>出品者</TableCell>
                  <TableCell>{complaint.sellerDisplayName}（ID：{complaint.sellerAccountId}）</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>購入者</TableCell>
                  <TableCell>{complaint.buyerDisplayName}（ID：{complaint.buyerAccountId}）</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>送信内容</TableCell>
                  <TableCell sx={{ whiteSpace: 'pre-wrap' }}>{complaint.content}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>送信日時</TableCell>
                  <TableCell>{moment(complaint.submittedAt).format("YYYY年M月D日 H:mm")}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          {
            complaint.state === 'submitted' ? (
              <Stack direction="row" spacing={1}>
                <Button variant="outlined" color="success" onClick={() => {
                  setOpenSalesVerificationDialog(true);
                }}>売上確定</Button>
                <Button variant="outlined" color="success" onClick={() => {
                  setOpenReservationCancellationWithChargeDialog(true);
                }}>予約キャンセル（キャンセル料金あり）</Button>
                <Button variant="outlined" color="error" onClick={() => {
                  setOpenReservationCancellationWithoutChargeDialog(true);
                }}>予約キャンセル（キャンセル料金なし）</Button>
              </Stack>
            ) : null
          }
        </Stack>
      </Container>

      <Dialog open={openSalesVerificationDialog}>
        <DialogTitle>売上確定</DialogTitle>
        <DialogContent>
          <DialogContentText>異議申し立てを認めず、出品者の売上を確定します。</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => cancelSalesVerificationButtonClicked()}>キャンセル</Button>
          <Button onClick={() => submitSalesVerificationButtonClicked()}>実行</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openReservationCancellationWithChargeDialog}>
        <DialogTitle>予約キャンセル（キャンセル料金あり）</DialogTitle>
        <DialogContent>
          <DialogContentText>異議申し立てを認めず、キャンセル料金100%を徴収して予約を事後キャンセルとします。</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => cancelReservationCancellationWithChargeButtonClicked()}>キャンセル</Button>
          <Button onClick={() => submitReservationCancellationWithChargeButtonClicked()}>実行</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openReservationCancellationWithoutChargeDialog}>
        <DialogTitle>予約キャンセル（キャンセル料金なし）</DialogTitle>
        <DialogContent>
          <DialogContentText>異議申し立てを認め、予約をキャンセルします（キャンセル料金は徴収しません）。</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => cancelReservationCancellationWithoutChargeButtonClicked()}>キャンセル</Button>
          <Button onClick={() => submitReservationCancellationWithoutChargeButtonClicked()}>実行</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}