import axios from 'axios';
import config from 'Config';

const ApiClient = axios.create({
  headers: {
    'Access-Control-Allow-Origin': '*',
  },
  baseURL: config.apiBaseUrl,
  withCredentials: true
});

ApiClient.interceptors.request.use((config) => {
  if (['post', 'put', 'patch', 'delete'].includes(config.method)) {
    config.headers['X-CSRF-Token'] = document.cookie.replace(/(?:(?:^|.*;\s*)CSRF-TOKEN\s*\=\s*([^;]*).*$)|^.*$/, "$1");
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

ApiClient.interceptors.response.use((response) => {
  return response;
}, (error) => {
  switch (error.response.status) {
    case 401:
      window.location.href = '/';
      break;
    default:
      break;
  }
  return Promise.reject(error);
});

export default ApiClient;